import React from "react";
import Carousel from "./Carousel";
import { countries } from "./Data";
import "./Carousel.css";
const InfiniteCardSlider = () => {
  return (
    <div className="advertisea__main">
      <div className="topContent"></div>
      {/* Carousel */}
      <Carousel images={countries} />
      {/* <h2 className="text-3xl md:text-5xl font-bold text-gray-800 mb-6">
            Unlock Your Potential
          </h2>
          <p className="text-lg md:text-xl text-gray-600 leading-relaxed">
            Discover a wealth of knowledge and expertise, curated to empower you
            on your learning journey toward success.
          </p>
          <h2 className="text-3xl md:text-5xl font-bold text-blue-700 mt-8 mb-6">
            Embrace Continuous Learning
          </h2>
          <p className="text-lg md:text-xl text-gray-700 leading-relaxed">
            Experience the transformative power of lifelong learning, where
            every tutorial brings new insights and growth opportunities.
          </p> */}
    </div>
  );
};

export default InfiniteCardSlider;
