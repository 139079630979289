import React, { useState, useContext } from "react";

import "./Navbar.css";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdLogout } from "react-icons/md";
import { logIn } from "../../Redux/Action";
import { AuthContext } from "../../context/AuthContext";
const NavbarTraine = () => {
  const navigate = useNavigate();
  const { setAuthUser } = useContext(AuthContext);

  const dispatch = useDispatch();
  const state = useSelector((state) => state.changeTheState || "N/A");
  console.log(state?.user, "nav");
  function logOut() {
    dispatch(logIn([]));
    navigate("/");
    setAuthUser(null);
  }
  return (
    <div>
      <div>
        <nav className=" flex items-center justify-between w-[100%] bg-gradient-to-r h-[10vh] from-slate-800 to-violet-900 hover:from-blue-900 hover:to-violet-900 text-white">
          <header className="flex items-center justify-between px-[10px] md:px-[100px] py-2 w-[100%]">
            {" "}
            <h2 className="md:text-[25px] md:font-bold md:w-[65%] capitalize">
              TecH-23
            </h2>
            {/* <div className="flex gap-8 font-[500]">
              <h5>
                <a href="#Solution"> Solution</a>
              </h5>
              <h5>
                {" "}
                <Link to="/">
                  {" "}
                  <a href="#About">About</a>
                </Link>
              </h5>
              <h5>
                {" "}
                <a href="#Support">Support</a>
              </h5>
            </div> */}
            <aside className="welcom__nav">
              <h1 className="font-bold text-lg text-white uppercase ">
                Welcome : {state.user.userCode ? state?.user.userCode : " "}
              </h1>
            </aside>
            <div>
              <header>
                {/* <ProfileIcon onClick={handleProfileClick} /> */}
                <button onClick={logOut} className="button__logout__navbar">
                  {" "}
                  LOGOUT
                </button>
                {/* {logoutVisible && <LogoutOption />} */}
              </header>
            </div>
            <div className="logout__fullscren">
              <button onClick={logOut} className="log__out__icon">
                {" "}
                <MdLogout />
              </button>
            </div>
          </header>
        </nav>
      </div>
    </div>
  );
};

export default NavbarTraine;
