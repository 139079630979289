import React from "react";
import "./LoadingSmall.css";
const LoadingSmall = () => {
  return (
    <div className="flex justify-center items-center w-[100%] h-[100vh]">
      <div class="loaderSmall"></div>
      
    </div>
  );
};

export default LoadingSmall;
