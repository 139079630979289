import React from "react";
import "./Footer.css";
import {
  FaSortAmountDownAlt,
  FaSortAmountUpAlt,
  FaRegArrowAltCircleUp,
} from "react-icons/fa";
const Footer = () => {
  // year has been added
  const today = new Date();
  const year = today.getFullYear();
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div>
      <footer id="Support" className="footerstyle z-50">
        <div className="toalignaside">
          {/* brand name is given in H tag */}
          <aside className="aside1">
            <h3 className="font-bold">TeCH23</h3>

            {/* email of the company  */}

            <p>Email : Support@tech23.net</p>
            {/* contact of company is given here */}
            <p>Contact : +254 798074411 </p>
          </aside>
          <aside className="aside2">
            {/* detail information about terms of use  */}
            <p>
              <h4 className="font-bold">Terms of Use</h4>
              This Software program is protected by the copyright law and
              international treaties.Unauthorized reproduction or redistribution
              of this program or any portion of it may result in severe civil
              and penalties, and will be prosecuted to maximum extent possible
              under the law.
            </p>
          </aside>
        </div>
        {/* copyright with symbol and all right reserved */}
        <div
          className="text-center p-3"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)", textAlign: "center" }}
        >
          {/* year has been added dynamically */}
          Copyright © {year} TeCH23. All Rights Reserved
        </div>
      </footer>
    </div>
  );
};

export default Footer;
