import React, { useState } from "react";

import "./Navbar.css";

import { FaBars, FaTimes } from "react-icons/fa";
// import "../src/Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdLogout } from "react-icons/md";
import { logIn } from "../../Redux/Action";
import { FaStepBackward } from "react-icons/fa";
const MenuNavbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  const dispatch = useDispatch();
  function logOut() {
    dispatch(logIn([]));
    navigate("/");
  }
  const state = useSelector((state) => state.changeTheState || "N/A");
  const userCode = state?.user?.userCode || "N/A";
  console.log(userCode, "nav"); // console.log(state);
  // console.log(state.user.userName);
  const handleCategory = (category) => {
    setSelectedCategory((prevCategory) =>
      prevCategory === category ? null : category
    );
  };
  return (
    <nav className="centernav sticky top-0 z-[1000]">
      <div className="navbar-container">
        <nav className="navbar block md:hidden">
          <div className="block md:hidden">
            <div className="fabar" onClick={toggleMenu}>
              {isMenuOpen ? <FaTimes /> : <FaBars />}
              {/* <FaBars /> */}
            </div>
          </div>
          <ul className={`nav-menu ${isMenuOpen ? "open" : ""}`}>
            <div className="hover-dropdown">
              <li onClick={() => navigate("/Modules")}>MODULE MASTER</li>
              <li onClick={() => navigate("/ChapterList")}>CHAPTER MASTER</li>
              <li onClick={() => navigate("/Author")}>AUTHOR MASTER</li>
              <li onClick={() => navigate("/Area")}>AREA MASTER</li>
              <li onClick={() => navigate("/Video")}>VIDEO UPLOAD MASTER</li>
              <li onClick={() => navigate("/ListofVideo")}>LIST OF VIDEO</li>
            </div>
          </ul>
        </nav>
        <div className="flex justify-between items-center w-[80%]">
          <aside className="welcom__nav">
            <h1 className="font-bold text-lg text-white ">TRAINER</h1>
          </aside>
          <aside>
            <h1 className="font-bold ml-1 md:text-lg text-white uppercase">
              Welcome : {userCode ? userCode : " "}
            </h1>
          </aside>
        </div>
        <aside>
          <button
            onClick={() => {
              navigate("/TrainerDashboard");
            }}
            className="button__logout__navbar"
          >
            {" "}
            DASHBOARD
          </button>
        </aside>

        <div className="logout__fullscren">
          <button
            onClick={() => {
              navigate("/TrainerDashboard");
            }}
            className="log__out__icon"
          >
            {" "}
            <FaStepBackward />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default MenuNavbar;
