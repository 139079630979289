import React from "react";
import MenuNavbar from "../../Components/Navbar/NavbarTrainer";
import Add from "../../Components/Advertising/InfiniteCardSlider";
import SideBar from "../../Components/SideBar/SideBar";
const TrainerDashboard = () => {
  return (
    <div>
      <MenuNavbar />
      <section className="flex all__page__main__section">
        <aside className="w-[20%]">
          <SideBar />
        </aside>
        <aside className="w-[80%]">
          <Add />
        </aside>{" "}
      </section>
    </div>
  );
};

export default TrainerDashboard;
