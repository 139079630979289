import React, { useEffect, useState, useRef } from "react";

import NavbarMain from "../../Components/Navbar/NavbarTraine";
import Select from "react-select";
import axios from "axios";
import ReactPlayer from "react-player";
import "../VideoDashboard/VideoDashboard.css";
import SmallLoading from "../../Components/Loading/LoadingSmall";
import LoadingSmall from "../../Components/Loading/LoadingSmall";
import { IoMdClose } from "react-icons/io";
import { BsFillMenuButtonWideFill } from "react-icons/bs";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import { FaPlayCircle } from "react-icons/fa";
import ChapterList from "../ChapterList/ChapterList";
import { IoArrowRedo } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

const VideoDashboard = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const playerRef = useRef(null);

  const handlePlayerReady = () => {
    const videoElement = playerRef.current.getInternalPlayer();
    if (videoElement) {
      videoElement.setAttribute("controlsList", "nodownload");
    }
  };
  const authToken = window.sessionStorage.getItem("auth-token");
  const [activeTab, setActiveTab] = useState("description");
  const [ModuleClicked, setModuleClicked] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [ModuleDetails, setModuleDetails] = useState([]);
  const [ModuleName, setModuleName] = useState(null);
  const [AreaDetails, setAreaDetails] = useState([]);
  const [chaptername, setChaptername] = useState(null);
  const [chapterListDetail, setChapterListDetail] = useState([]);
  const [VedioListDetail, setVedioListDetail] = useState([]);
  const [videoAllDetails, SetVideoAllDetails] = useState([]);
  const [ModuleNumberforvideo, setModuleNumberforvideo] = useState("");
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isScreenBlock, setScreenBlock] = useState(false);
  const [isSmallLoading, setSmallLoading] = useState(false);
  const [selectedArea, setSelectedArea] = useState(null);
  const [Videoplayer, setVideoplayer] = useState([]);
  const [filteredModules, setFilteredModules] = useState([]);
  const state = useSelector((state) => state.changeTheState);
  const emailUser = state.user.emailId;
  const moduleNameTopass = state.user.traineeOrTrainer;
  const sectionRef = useRef(null);

  const handleAreaClick = (areaName) => {
    setSelectedArea((prevArea) => (prevArea === areaName ? null : areaName));
    setSelectedChapter(null);
    setSelectedVideo(null);
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName === activeTab ? null : tabName);
  };

  const fetchAreas = async () => {
    try {
      const response = await axios.get(`${BASEURL}/getAreas`, {
        headers: {
          "auth-token": authToken,
        },
      });
      setAreaDetails(response.data);
    } catch (error) {
      console.error("Error fetching areas:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllList = async () => {
    try {
      const response = await axios.get(`${BASEURL}/getAllList`, {
        headers: {
          "auth-token": authToken,
        },
      });
      SetVideoAllDetails(response.data);
    } catch (error) {
      console.error("Error fetching all list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const TraineeInterface = async () => {
    setIsLoading(true);
    await fetchAreas();
    await fetchAllList();
  };

  const userRight = async () => {
    try {
      const responseRight = await axios.post(
        `${BASEURL}/getuserrights`,
        {
          EMAIL: emailUser,
          MODULENAME: moduleNameTopass,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      const allowedModules = responseRight.data.map(
        (item) => item.OM_ACCOUNT_MENU
      );

      const organizedData = {};
      videoAllDetails.forEach((item) => {
        const {
          MODULEID,
          MODULENAME,
          CHAPTERNAME,
          VIDEONAME,
          VIDEOID,
          AREANAME,
        } = item;

        if (!organizedData[MODULENAME]) {
          organizedData[MODULENAME] = {
            moduleName: MODULENAME,
            areas: {},
          };
        }

        if (!organizedData[MODULENAME].areas[AREANAME]) {
          organizedData[MODULENAME].areas[AREANAME] = {
            areaName: AREANAME,
            chapters: [],
          };
        }

        const chapterIndex = organizedData[MODULENAME].areas[
          AREANAME
        ].chapters.findIndex((chapter) => chapter.chapterName === CHAPTERNAME);

        if (chapterIndex === -1) {
          organizedData[MODULENAME].areas[AREANAME].chapters.push({
            chapterName: CHAPTERNAME,
            videos: [{ videoName: VIDEONAME, videoUrl: VIDEOID }],
          });
        } else {
          organizedData[MODULENAME].areas[AREANAME].chapters[
            chapterIndex
          ].videos.push({
            videoName: VIDEONAME,
            videoUrl: VIDEOID,
          });
        }
      });

      const filteredData = Object.keys(organizedData)
        .filter((moduleName) => allowedModules.includes(moduleName))
        .reduce((obj, key) => {
          obj[key] = organizedData[key];
          return obj;
        }, {});

      setFilteredModules(filteredData);
    } catch (error) {
      console.error("Error fetching user rights:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    TraineeInterface();
  }, []);

  useEffect(() => {
    if (videoAllDetails.length > 0 && moduleNameTopass && emailUser) {
      userRight();
    }
  }, [videoAllDetails, moduleNameTopass, emailUser]);

  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [activeModule, setActiveModule] = useState(null);

  const handleModuleClick = (moduleId) => {
    setActiveModule((prevModule) =>
      prevModule === moduleId ? null : moduleId
    );
    setSelectedModule((prevModule) =>
      prevModule === moduleId ? null : moduleId
    );
    setSelectedChapter(null);
    setSelectedVideo(null);
  };

  const handleChapterClick = (chapterName) => {
    setSelectedChapter(chapterName);
    setSelectedVideo(null);
  };

  const handleVideoClick = async (videoUrl) => {
    setSelectedVideo(videoUrl);

    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }

    try {
      setScreenBlock(true);
      setSmallLoading(true);

      const response = await axios.post(
        `${BASEURL}/getVideoDetails`,
        { VIDEOID: videoUrl.videoUrl },
        { headers: { "auth-token": authToken } }
      );

      if (response.data[0].APPROVALSTATUS === "APPROVED") {
        setVideoplayer(response.data);
        setScreenBlock(false);
      }
    } catch (error) {
      console.error("Error fetching video details:", error);
    } finally {
      setScreenBlock(false);
      setSmallLoading(false);
    }
  };

  const closePopup = () => {
    setScreenBlock(false);
    setPopupVisible(false);
  };

  const createBlobURL = (uint8Array) => {
    const buffer = new Uint8Array(uint8Array);
    const file = new File([buffer], "video.mp4", { type: "video/mp4" });
    return URL.createObjectURL(file);
  };

  const initialComments = [
    {
      id: 1,
      user: "John Doe",
      comment: "This course is excellent!",
      time: "2 hours ago",
    },
    {
      id: 2,
      user: "Alice Johnson",
      comment: "I really enjoyed learning from this course.",
      time: "3 hours ago",
    },
    {
      id: 3,
      user: "Emily Smith",
      comment: "Great content and well-explained!",
      time: "5 hours ago",
    },
    {
      id: 4,
      user: "Mike Williams",
      comment: "Helped me a lot. Thank you!",
      time: "7 hours ago",
    },
    {
      id: 5,
      user: "Sophia Clark",
      comment: "The course exceeded my expectations.",
      time: "10 hours ago",
    },
  ];

  const [comments, setComments] = useState(initialComments);
  const [newComment, setNewComment] = useState("");

  const handleAddComment = () => {
    if (newComment.trim() !== "") {
      const newId = comments.length + 1;
      const commentObject = {
        id: newId,
        user: "New User",
        comment: newComment,
        time: "Just now",
      };
      setComments([...comments, commentObject]);
      setNewComment("");
    }
  };

  return (
    <div className="uppercase">
      <div>
        <NavbarMain />
      </div>
      <div className={isScreenBlock ? "blocked-screen" : ""}></div>
      <main className="flex video__dashboard">
        <div className="sidebar w-[20%] bg-slate-50 h-[90vh] overflow-auto">
          {/* ... (existing code) */}
          <ul>
            <li style={{ textDecoration: "none" }}>
              <h1 className="font-bold ml-2 text-xl"> Modules</h1>
              <div>
                {Object.keys(filteredModules).map((moduleName) => (
                  <div
                    key={moduleName}
                    className={`cursor-pointer px-2 py-1 border-b-2 ${
                      activeModule === moduleName
                        ? "bg-slate-100"
                        : "hover:bg-slate-200"
                    }`}
                  >
                    <h3
                      onClick={() => {
                        handleModuleClick(moduleName);
                        setModuleClicked(moduleName);
                      }}
                      className="flex justify-between"
                    >
                      <p> {filteredModules[moduleName].moduleName}</p>
                      <p>
                        {" "}
                        {activeModule === moduleName ? (
                          <FaSortUp />
                        ) : (
                          <FaSortDown />
                        )}
                      </p>
                    </h3>
                    {selectedModule === moduleName && (
                      <div className="pl-2 ">
                        {Object.keys(filteredModules[moduleName].areas).map(
                          (areaName) => (
                            <div key={areaName}>
                              <p
                                style={{ textDecoration: "underline" }}
                                onClick={() => handleAreaClick(areaName)}
                              >
                                {areaName ? areaName : "No area uploaded"}
                              </p>
                              {selectedArea === areaName && (
                                <div className="pl-1 ">
                                  {filteredModules[moduleName].areas[
                                    areaName
                                  ].chapters.map((chapter, index) => (
                                    <div key={index}>
                                      <div
                                        className="flex items-center"
                                        onClick={() =>
                                          handleChapterClick(
                                            chapter.chapterName
                                          )
                                        }
                                      >
                                        <p
                                          style={{
                                            transform: "rotate(270deg)",
                                          }}
                                        >
                                          {" "}
                                          <IoArrowRedo />{" "}
                                        </p>
                                        <p
                                          style={{
                                            textDecoration: "underline",
                                          }}
                                        >
                                          {" "}
                                          {chapter.chapterName
                                            ? chapter.chapterName
                                            : "No chapter uploaded"}
                                        </p>
                                      </div>
                                      {selectedChapter ===
                                        chapter.chapterName && (
                                        <div className="pl-4 ">
                                          {chapter.videos.map((video, idx) => (
                                            <p
                                              key={idx}
                                              onClick={() =>
                                                handleVideoClick(video)
                                              }
                                            >
                                              {video.videoName && (
                                                <div className="flex items-center hover:text-violet-800 ">
                                                  <p className="mr-1 text-sm ">
                                                    <FaPlayCircle />{" "}
                                                  </p>
                                                  <p>
                                                    {video.videoName
                                                      ? video.videoName
                                                      : "No video available"}
                                                  </p>
                                                </div>
                                              )}
                                            </p>
                                          ))}
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </li>
          </ul>
        </div>
        {isSmallLoading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingSmall />
          </div>
        )}
        {/* {isSmallLoading && (
          <div className="flex justify-center items-center z-50">
            <SmallLoading />
          </div>
        )} */}
        <div className="w-[80%] py-2">
          {ModuleClicked && (
            <h1 className="p-2 font-bold  text-lg">
              {ModuleClicked ? ModuleClicked : "N/A"}
            </h1>
          )}

          <div>
            {" "}
            {Videoplayer.map((item, index) => (
              <section key={index} ref={sectionRef}>
                <div className="flex justify-center w-[100%] ">
                  {/* {item.VIDEOURL && (
                    <ReactPlayer
                      url={item?.VIDEOURL}
                      controls
                      width="95%"
                      maxHeight="240"
                      onError={(e) => console.error("Error playing video:", e)}
                    />
                  )} */}

                  <ReactPlayer
                    ref={playerRef}
                    url={
                      item.VIDEOUPLOADED !== null
                        ? item.VIDEOUPLOADED
                        : item.VIDEOURL
                    }
                    controls
                    width="95%"
                    maxHeight="240"
                    onReady={handlePlayerReady}
                    onError={(e) => console.error("Error playing video:", e)}
                    className="h-[200px]"
                  />
                </div>

                <div className="all__module " style={{ marginTop: "20px" }}>
                  <h1
                    className={activeTab === "description" ? "active" : ""}
                    onClick={() => handleTabClick("description")}
                  >
                    Description
                  </h1>
                  <h1
                    className={activeTab === "notes" ? "active" : ""}
                    onClick={() => handleTabClick("notes")}
                  >
                    Notes
                  </h1>
                  <h1
                    className={activeTab === "q&a" ? "active" : ""}
                    onClick={() => handleTabClick("q&a")}
                  >
                    Q&A
                  </h1>
                  <h1
                    className={activeTab === "comments" ? "active" : ""}
                    onClick={() => handleTabClick("comments")}
                  >
                    Comments
                  </h1>
                </div>
                <div className="all__module " style={{ marginTop: "20px" }}>
                  {activeTab === "notes" && (
                    <div className="w-[100%]">
                      <div className="flex items-end justify-between">
                        <h2 className="text-[25px] font-bold">Notes</h2>{" "}
                        <div className="p-1">
                          <button className="px-2 py-1 mx-1 rounded bg-slate-300">
                            Cancel
                          </button>{" "}
                          <button className="px-2 py-1 mx-1 rounded bg-slate-300">
                            Save Notes
                          </button>
                        </div>
                      </div>
                      <textarea class="textmax__width border-solid border-slate-500 border-[2px] min-w-[800px] max-w-[800px] min-h-[200px] max-h-[200px] p-2 "></textarea>
                    </div>
                  )}
                  {activeTab === "q&a" && (
                    <div>
                      <h2 className="text-[25px] font-bold">Content Q&A</h2>
                    </div>
                  )}
                  {activeTab === "comments" && (
                    <div>
                      <div className="add-comment">
                        <div className="flex items-end justify-between">
                          <h2 className="text-[25px] font-bold">Comments</h2>{" "}
                          <div className="p-1">
                            <button
                              className="px-2 py-1 mx-1 rounded bg-slate-300"
                              // onClick={handleAddComment}
                            >
                              Add Comment
                            </button>
                          </div>
                        </div>
                        <textarea
                          defaultValue={newComment}
                          // onChange={(e) => setNewComment(e.target.value)}
                          class="textmax__width border-solid border-slate-500 border-[2px] w-[100%] md:min-w-[800px]  md:max-w-[800px] min-h-[60px] max-h-[60px] p-1 md:p-2  "
                          placeholder="Add your comment..."
                        ></textarea>
                      </div>
                      <div className="comments-section">
                        <h2 className="text-[19px] font-bold">
                          Comments by Others
                        </h2>
                        <ul className="comment-list mt-3">
                          {comments.map((comment) => (
                            <li key={comment.id}>
                              <div className="comment-info">
                                <span className="user-avatar rounded-[50%] bg-slate-200 w-8 inline-block text-center">
                                  {comment.user.slice(0, 2).toUpperCase()}
                                </span>
                                <span className="ml-2 font-bold">
                                  {comment.user}:
                                </span>

                                <div className="user-comment ml-10">
                                  <div> {comment.comment}</div>
                                  <h2 className="comment-time font-serif text-sm">
                                    {comment.time}
                                  </h2>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                  {activeTab === "description" && (
                    <div>
                      <h2 className="text-[25px] font-bold">
                        About this course
                      </h2>

                      <div className=" uppercase video__brief">
                        <aside>
                          <p>VIDEO NAME :</p>
                          <h1>{item.VIDEONAME ? item.VIDEONAME : "N/A"}</h1>
                        </aside>
                        <aside>
                          {" "}
                          <p>APPROVAL STATUS : </p>
                          <h1>
                            {" "}
                            {item.APPROVALSTATUS ? item.APPROVALSTATUS : "N/A"}
                          </h1>
                        </aside>
                        <aside>
                          {" "}
                          <p>CREATED DATE : </p>
                          <h1>
                            {" "}
                            {item.CREATEDDATE
                              ? new Date(item.CREATEDDATE).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  }
                                )
                              : "N/A"}{" "}
                          </h1>
                        </aside>
                        <aside>
                          {" "}
                          <p>CREATED BY :</p>
                          <h1>{item.CREATEDBY ? item.CREATEDBY : "N/A"}</h1>
                        </aside>
                        <aside>
                          <p> VIDEO BRIEF : </p>
                          <h1> {item.VIDEOBRIEF ? item.VIDEOBRIEF : "N/A"}</h1>
                        </aside>
                      </div>
                    </div>
                  )}
                </div>
              </section>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default VideoDashboard;
