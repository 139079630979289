import changeTheState from "./loginLogout";

import changeLoginState from "./loginstatred";
import selectStatements from "./statements"
import { combineReducers } from "redux";

const rootReducers = combineReducers({
  changeTheState,
  changeLoginState,
  selectStatements,
});

export default rootReducers;
