import React from "react";
import axios from "axios";

function VideoTest() {
  const handleAuthentication = async () => {
    try {
      const authResponse = await axios.get("http://localhost:3008/auth");
      console.log("Authentication successful", authResponse);
    } catch (error) {
      console.error("Error occurred during authentication", error);
    }
  };

  const handleUpload = async () => {
    try {
      const uploadResponse = await axios.post("http://localhost:3008/upload");
      console.log("File uploaded successfully", uploadResponse);
    } catch (error) {
      console.error("Error uploading file", error);
    }
  };

  return (
    <div>
      <h1>OneDrive Video Upload</h1>
      <button onClick={handleAuthentication} className="m-3 bg-slate-400">
        Authenticate with OneDrive
      </button>
      <button onClick={handleUpload} className="m-3 bg-slate-400">
        Upload Video
      </button>
    </div>
  );
}

export default VideoTest;
