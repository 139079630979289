import {
  Navigate,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import ProtectedRoute from "./context/ProtectedRoute";
//Client related import
import NavbarMain from "../src/Components/NavbarMain";
import LandingPage from "./Pages/LandingPage";
import Login from "./Pages/Login/Login";
import VideoDashboard from "./Pages/VideoDashboard/VideoDashboard";
import Footer from "./Components/Footer/Footer";
import Modules from "./Pages/Modules/Modules";
import Area from "./Pages/Area/Area";
import Video from "./Pages/Video/Video";
import Author from "./Pages/Authors/Author";
import ChapterList from "./Pages/ChapterList/ChapterList";
import TrainerDashboard from "./Pages/TrainerDashboard/TrainerDashboard";
import NotFound from "./Components/NotFound/NotFound";
import ForgotPwd from "./Components/ForgotPwd";
import VideoTest from "./VideoTest/VideoTest";
import ListofVideo from "./Pages/ListofVideo/ListofVideo";
import Test from "./Components/Test";
function App() {
  return (
    <div>
      <Router>
        <Routes>
          {/* Client related */}
          <Route path="/*" element={<NotFound />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/NavbarMain" element={<NavbarMain />} />
          <Route path="/Login" element={<Login />} />
          <Route
            path="/VideoDashboard"
            element={
              <ProtectedRoute>
                <VideoDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Modules"
            element={
              <ProtectedRoute>
                <Modules />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Area"
            element={
              <ProtectedRoute>
                <Area />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Video"
            element={
              <ProtectedRoute>
                <Video />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Author"
            element={
              <ProtectedRoute>
                <Author />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ChapterList"
            element={
              <ProtectedRoute>
                <ChapterList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/TrainerDashboard"
            element={
              <ProtectedRoute>
                <TrainerDashboard />
              </ProtectedRoute>
            }
          />
          <Route path="/ForgotPwd" element={<ForgotPwd />} />
          <Route
            path="/VideoTest"
            element={
              <ProtectedRoute>
                <VideoTest />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ListofVideo"
            element={
              <ProtectedRoute>
                <ListofVideo />
              </ProtectedRoute>
            }
          />
          <Route
            path="/test"
            element={
              <ProtectedRoute>
                <Test />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
      <div className="mt-[40vh] relative">
        <Footer />
      </div>
    </div>
  );
}

export default App;
