import React, { useEffect, useState } from "react";

import Select from "react-select";
import SideBar from "../../Components/SideBar/SideBar";
import LoadingSpinner from "../../Components/Loading/Loading";
import axios from "axios";
import MenuNavbar from "../../Components/Navbar/MenuNavbar";
import { FaSearch } from "react-icons/fa";

function Author() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [selectedOption, setSelectedOption] = useState(null);
  const [AuthorId, setAuthorId] = useState("");
  const [AuthorDetails, setAuthorDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  function AuthorInterface() {
    setIsLoading(true);
    axios
      .get(`${BASEURL}/getAuthorList`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setAuthorDetails(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    // axios
    //   .get(`${BASEURL}/getNewModuleId`, {
    //     headers: {
    //       "auth-token": authToken,
    //     },
    //   })
    //   .then((response) => {
    //     console.log(response.data[0]);
    //     setAuthorId(response.data[0]);
    //     setIsLoading(false);
    //   });
  }
  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    AuthorInterface();
    scrollToSection();
  }, []);
  const filteredData = AuthorDetails.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["AUTHORID", "AUTHORNAME", "AUTHOREMAIL", "ACTIVE"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <div className="">
      <MenuNavbar />
      <section className="flex all__page__main__section">
        <aside className="w-[20%]">
          <SideBar />
        </aside>
        <aside className="w-[80%]">
          <header className="header__of__main flex justify-between mr-5">
            <h1 className="header__of__page">Authors Master</h1>
          </header>

          <div>
            <div className="searchhhhhh">
              <p>Search : </p>
              <input
                className="input_1"
                required
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              ></input>
              <div className="search__icon">
                {" "}
                <FaSearch />
              </div>
            </div>
            <aside className="mt-[20px] table__video__app">
              <table class="custom-table">
                <thead class="header-cell">
                  <td>AUTHOR&nbsp;ID</td>
                  <td>AUTHOR&nbsp;NAME</td>
                  <td>AUTHOR&nbsp;EMAIL</td>
                  <td>ACTIVE</td>
                  {/* <td>EDIT</td> */}
                </thead>
                <tbody>
                  {filteredData.map((item, index) => (
                    <tr key={index} className="table-row">
                      <td className="alignleft">
                        {item.AUTHORID ? item.AUTHORID : "N/A"}
                      </td>
                      <td>{item.AUTHORNAME ? item.AUTHORNAME : "N/A"}</td>
                      <td>{item.AUTHOREMAIL ? item.AUTHOREMAIL : "N/A"}</td>
                      <td>{item.ACTIVE ? item.ACTIVE : "N/A"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </div>
        </aside>{" "}
      </section>
    </div>
  );
}

export default Author;
