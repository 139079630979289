import React from "react";
import "../Loading/Loading.css";
const Loading = () => {
  return (
    <div>
      <section className="loader__center">
        <span class="loader"></span>
        <p> Loading...</p>
      </section>
    </div>
  );
};

export default Loading;
