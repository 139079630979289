import React, { useEffect, useState, useRef } from "react";
import MenuNavbar from "../../Components/Navbar/MenuNavbar";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../../Components/SideBar/SideBar";
import LoadingSpinner from "../../Components/Loading/Loading";
import axios from "axios";
import ReactPlayer from "react-player";
import { RiDeleteBin2Fill } from "react-icons/ri";
import UploadingSpinner from "../../Components/Loading/UploadingSpinner";
import "../ListofVideo/ListofVideo.css";
import { BiSolidEditAlt } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import SmallLoading from "../../Components/Loading/LoadingSmall";
import LoadingSmall from "../../Components/Loading/LoadingSmall";

import { TiTickOutline } from "react-icons/ti";
import { FaSearch } from "react-icons/fa";
function Video() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [VideoName, setVideoName] = useState("");
  const [VedioId, setVedioId] = useState("");
  const [VedioDetails, setVedioDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ModuleName, setModuleName] = useState([]);
  const [ModuleNamemapped, setModuleNamemapped] = useState("");
  const [ModuleId, setModuleId] = useState("");
  const [ChapterId, setChapterId] = useState("");
  const [AuthorId, setAuthorId] = useState("");
  const [AreaId, setAreaId] = useState("");
  const [chapterListDetail, setChapterListDetail] = useState([]);
  const [AreaDetails, setAreaDetails] = useState([]);
  const [SelectedChapterName, SetSelectedChapterName] = useState("");
  const [isloading, setisloading] = useState(false);
  const [AuthorDetails, setAuthorDetails] = useState([]);
  const [VideoBrief, setVideoBrief] = useState("");
  const [VideoURL, SetVideoURL] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isScreenBlock, setScreenBlock] = useState(false);
  const [isSmallLoading, setSmallLoading] = useState(false);
  const [isUploadMode, setIsUploadMode] = useState(true);
  const [isURLMode, setIsURLMode] = useState(true);
  const [VideoDetails, setVideoDetails] = useState([]);
  const [VideoUrllink, setVideoUrllink] = useState("");
  const [areanameinfiled, setareanameinfiled] = useState("");
  const [search, setSearch] = useState("");
  const [selectedModule, setSelectedModule] = useState(null);
  const [listofvideovidototable, setlistofvideovidototable] = useState([]);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [Videoplayer, setVideoplayer] = useState([]);
  const [modules, setModules] = useState([]);
  const [Authorname, setAuthorname] = useState("");
  const state = useSelector((state) => state.changeTheState);
  console.log(state.user.userCode);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const getuserRights = useSelector((state) => state.changeTheState);
  const userRights = getuserRights?.user?.userType;
  const userPassedBY = getuserRights?.user?.userCode;
  const playerRef = useRef(null);
  const handleChange = (selectedOption) => {
    setVideoName(selectedOption);
  };

  const handlePlayerReady = () => {
    const videoElement = playerRef.current.getInternalPlayer();
    if (videoElement) {
      videoElement.setAttribute("controlsList", "nodownload");
    }
  };
  function videodata() {
    setisloading(true);

    axios
      .get(`${BASEURL}/getAllVideosfortable`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setVideoDetails(response.data);
        setisloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    videodata();
    fetchModules(); // Add this line
  }, []);

  const fetchModules = () => {
    axios
      .get(`${BASEURL}/getModuleMaster`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        const moduleOptions = response.data.map((module) => ({
          value: module.MODULENAME,
          label: module.MODULENAME,
        }));
        setModules(moduleOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function VedioInterface() {
    setIsLoading(true);

    axios
      .get(`${BASEURL}/getAllVideosfortable`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setlistofvideovidototable(response.data);
        setisloading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${BASEURL}/getNewVideoId`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        console.log(response.data[0]);
        setVedioId(response.data[0]?.VIDEOID);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${BASEURL}/getModuleMaster`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setModuleName(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${BASEURL}/getAreas`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setAreaDetails(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${BASEURL}/getAuthorList`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setAuthorDetails(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${BASEURL}/getAllVideosfortable`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setVideoDetails(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    VedioInterface();
  }, []);

  function HandleSelectedModule(name) {
    console.log(name.value);
    setModuleNamemapped(name.value);
    const ModuleNameSelected = ModuleName.find(
      (item) => item.MODULENAME === name.value
    );
    setModuleId(ModuleNameSelected.MODULEID);
    SetSelectedChapterName("");
    setareanameinfiled("");

    axios
      .post(
        `${BASEURL}/getChapterlist`,
        {
          MODULEID: ModuleNameSelected.MODULEID,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setChapterListDetail(response.data);
        console.log(response.data);
        console.log(response.data, "dattt");
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching List Of getChapterlist:", error);
        setisloading(false);
      });
  }
  function HandleChapterName(selectedItem) {
    SetSelectedChapterName(selectedItem);
    console.log(selectedItem);
    const selectedChappterid = chapterListDetail.find(
      (item) => item.CHAPTERNAME === selectedItem
    );
    setChapterId(selectedChappterid.CHAPTERID);
    setareanameinfiled("");
    console.log(selectedChappterid);
  }

  function HandleAreaName(selecteditem) {
    setareanameinfiled(selecteditem);
    const selectedareaid = AreaDetails.find(
      (item) => item.AREANAME === selecteditem
    );
    setAreaId(selectedareaid.AREAID);
    console.log(selectedareaid.AREAID);
  }

  function HandleAuthorName(selecteditem) {
    setAuthorname(selecteditem);
    const selectedAuthorid = AuthorDetails.find(
      (item) => item.AUTHORNAME === selecteditem
    );
    setAuthorId(selectedAuthorid.AUTHORID);
    console.log(selectedAuthorid.AUTHORID);
  }

  function HandleURLupload(e) {
    SetVideoURL(e.target.files);
    setSelectedFile(e.target.files[0]);
    setIsUploadMode(true);
    setIsURLMode(false);
  }
  function handleFileDelete() {
    SetVideoURL(null);
    setSelectedFile(null);
  }
  function HandleURLlink(e) {
    setVideoUrllink(e.target.value);
    setIsUploadMode(false);
    setIsURLMode(true);
  }
  const createBlobURL = (uint8Array) => {
    const buffer = new Uint8Array(uint8Array);
    const file = new File([buffer], "video.mp4", { type: "video/mp4" });
    return URL.createObjectURL(file);
  };

  function save() {
    console.log(VedioId);
    console.log(VideoName);
    console.log(ModuleId);
    console.log(ChapterId);
    console.log(AreaId);
    console.log(AuthorId);
    console.log(VideoBrief);
    // console.log(VideoURL?.name);
    // console.log(VideoURL);
    console.log("CREATEDBY", state.user.userCode);
    console.log(VideoUrllink);
    if (!VideoName || !ModuleId || !AreaId || !AuthorId || !ChapterId) {
      alert("Enter the Required Details");
      setIsLoading(false);
    } else {
      let video = VideoURL ? VideoURL[0] : "N/A";
      const formData = new FormData();
      formData.append("VIDEOID", VedioId);
      formData.append("VIDEONAME", VideoName);
      formData.append("MODULEID", ModuleId);
      formData.append("CHAPTERID", ChapterId);
      formData.append("AREAID", AreaId);
      formData.append("AUTHORID", AuthorId);
      formData.append("VIDEOBRIEF", VideoBrief);
      formData.append("image", video);
      formData.append("CREATEDBY", state.user.userCode);
      formData.append("VIDEOURL", VideoUrllink);
      setScreenBlock(true);
      setSmallLoading(true);
      axios
        .post(`${BASEURL}/insertvideos`, formData, {
          headers: {
            "auth-token": authToken,
          },
        })
        .then((response) => {
          alert(response.data.message);
          setScreenBlock(false);
          setSmallLoading(false);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  // function save() {
  //   console.log(VedioId.VIDEOID);
  //   console.log(VideoName);
  //   console.log(ModuleId);
  //   console.log(ChapterId);
  //   console.log(AreaId);
  //   console.log(AuthorId);
  //   console.log(VideoBrief);
  //   console.log(VideoURL.name);
  //   console.log(VideoURL);
  //   axios
  //     .post(
  //       `${BASEURL}/insertvideos`,
  //       {
  //         VIDEOID: VedioId.VIDEOID,
  //         VIDEONAME: VideoName,
  //         MODULEID: ModuleId,
  //         CHAPTERID: ChapterId,
  //         AREAID: AreaId,
  //         AUTHORID: AuthorId,
  //         VIDEOURL: VideoURL,
  //         VIDEOBRIEF: VideoBrief,
  //         CREATEDBY: "tetser",
  //       },
  //       {
  //         headers: {
  //           "auth-token": authToken,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       console.log(response.data);
  //     })
  //     .catch((error) => {
  //       alert("Error :", error);
  //       setisloading(false);
  //     });
  // }
  // const decodedData = Buffer.from(data, "binary").toString("base64");
  if (isLoading) {
    return <LoadingSpinner />;
  }
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      Width: "400px",
      marginTop: "0px",
      minHeight: "initial",
      height: "45px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  //   function handleInputs(e) {
  //     const { name, value } = e.target;
  //     SetVehinputs({ ...vehinputs, [name]: value });
  //   }
  const uniqueAreaNames = [
    ...new Set(chapterListDetail.map((item) => item.AREANAME)),
  ];

  const options = uniqueAreaNames.map((areaName) => ({
    value: areaName,
    label: areaName,
  }));

  const filteredData = listofvideovidototable.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0 && !selectedModule) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["VIDEONAME", "MODULENAME", "CHAPTERNAME", "AUTHORNAME"].some(
        (key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        }
      );
    });

    const moduleMatch = selectedModule
      ? item.MODULENAME === selectedModule.value
      : true;

    return textMatch && moduleMatch;
  });

  const handleRowClick = (videoid) => {
    setScreenBlock(true);
    setSelectedVideo(videoid);
    setSmallLoading(true);
    axios
      .post(
        `${BASEURL}/getVideoDetails`,
        {
          VIDEOID: videoid,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setVideoplayer(response.data);
        console.log(response.data, "setVideoplayer");
        // setVideoUrllink(response.data[0].VIDEOUPLOADED);
        setPopupVisible(true);
        setSmallLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function approveVideo(videoid, approvalStatus) {
    const confirm = window.confirm(
      "Are you sure you want to Approve this video"
    );
    if (!confirm) {
      console.log("video rejected by user");
      return;
    }
    console.log(videoid, approvalStatus, userRights, userPassedBY);
    if (confirm) {
      if (approvalStatus === "APPROVED") {
        alert("video already approved");
      }
      //  else if (userRights === false) {
      //   alert("user dont have the rights to approve the video");
      // }
      else {
        axios
          .post(
            `${BASEURL}/videoapproval`,
            {
              VIDEOID: videoid,
              STATUSUPDATEBY: userPassedBY,
              APPROVEDSTATUS: "APPROVED",
            },
            {
              headers: {
                "auth-token": authToken,
              },
            }
          )
          .then((response) => {
            console.log(response);
            alert(response.data.message);
          })
          .catch((err) => {
            console.log(err);
          });
        console.log(
          videoid,
          approvalStatus,
          userRights,
          userPassedBY,
          "videoapproval"
        );
      }
    }
  }
  function deleteVideo(videoid) {
    const confirm = window.confirm(
      "Are you sure you want to DELETE this video"
    );
    if (!confirm) {
      console.log(" CANCELLED deletion by user");
      return;
    }
    // console.log(videoid, approvalStatus, userRights, userPassedBY);
    if (confirm) {
      // if (approvalStatus === "APPROVED") {
      //   alert("video already approved");
      // }
      //  else if (userRights === false) {
      //   alert("user dont have the rights to approve the video");
      // }

      axios
        .post(
          `${BASEURL}/deleteVideos`,
          {
            VIDEOID: videoid,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          console.log(response);
          alert(response.data.message);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  const closePopup = () => {
    setScreenBlock(false);
    setPopupVisible(false);
  };

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const FetchTableValuesInFields = (item) => {
    console.log(item);
    setVedioId(item.VIDEOID);
    setVideoName(item.VIDEONAME);
    setModuleNamemapped(item.MODULENAME);
    setAuthorname(item.AUTHORNAME);
    SetSelectedChapterName(item.CHAPTERNAME);
    setModuleId(item.MODULEID[0]);
    setChapterId(item.CHAPTERID[0]);
    setAreaId(item.AREAID[0]);
    setareanameinfiled(item.AREANAME[0]);
    setVideoBrief(item.VIDEOBRIEF);
    setAuthorId(item.AUTHORID[0]);
    SetVideoURL(item.VIDEOURL);
  };
  console.log(filteredData);
  return (
    <div className="">
      <MenuNavbar />
      <section className="flex all__page__main__section">
        <aside className="w-[20%]">
          <SideBar />
        </aside>
        <aside className="w-[80%]">
          {" "}
          <header className="header__of__main flex justify-between mr-5">
            <h1 className="header__of__page">VIDEOS Master</h1>
            <div>
              <button className="button_style" type="submit" onClick={save}>
                SAVE
              </button>
            </div>
          </header>
          <aside>
            <main className="main__admin my-[20px]">
              <div>
                <h1>VIDEO ID</h1>
                <input
                  type="text"
                  value={VedioId.VIDEOID ? VedioId.VIDEOID : VedioId}
                  disabled
                />
              </div>
              <div>
                <h1>
                  VIDEO NAME <span style={{ color: "red " }}>*</span>
                </h1>
                <input
                  type="text"
                  name="videoname"
                  value={VideoName}
                  onChange={(e) => handleChange(e.target.value)}
                />
              </div>
              <div>
                <h1>
                  MODULE NAME <span style={{ color: "red " }}>*</span>
                </h1>
                <div style={{ width: "300px", margin: "5px" }}>
                  <Select
                    style={customStyless}
                    onChange={(selectedvalue) =>
                      HandleSelectedModule(selectedvalue)
                    }
                    placeholder="Select a module name..."
                    options={ModuleName.map((item) => ({
                      value: item.MODULENAME,
                      label: item.MODULENAME,
                    }))}
                    value={{ value: ModuleNamemapped, label: ModuleNamemapped }}
                  />
                </div>
              </div>
              <div>
                <h1>
                  CHAPTER NAME <span style={{ color: "red " }}>*</span>
                </h1>
                <div style={{ width: "300px", margin: "5px" }}>
                  <Select
                    type="text"
                    onChange={(e) => HandleChapterName(e.value)}
                    value={{
                      value: SelectedChapterName
                        ? SelectedChapterName
                        : "Select a Chapter name...",
                      label: SelectedChapterName
                        ? SelectedChapterName
                        : "Select a Chapter name...",
                    }}
                    placeholder="Select a Chapter name..."
                    options={chapterListDetail.map((item) => ({
                      value: item.CHAPTERNAME,
                      label: item.CHAPTERNAME,
                    }))}
                  />
                </div>
              </div>
              <div>
                <h1>
                  AREA NAME <span style={{ color: "red " }}>*</span>
                </h1>
                <div style={{ width: "300px", margin: "5px" }}>
                  <Select
                    type="text"
                    value={{
                      value: areanameinfiled
                        ? areanameinfiled
                        : "Select a Area name...",
                      label: areanameinfiled
                        ? areanameinfiled
                        : "Select a Area name...",
                    }}
                    onChange={(e) => HandleAreaName(e.value)}
                    placeholder="Select a Area name..."
                    options={options}
                  />
                </div>
              </div>
              <div>
                <h1>
                  AUTHOR NAME <span style={{ color: "red " }}>*</span>
                </h1>
                <div style={{ width: "300px", margin: "5px" }}>
                  <Select
                    onChange={(e) => HandleAuthorName(e.value)}
                    options={AuthorDetails.map((item) => ({
                      value: item.AUTHORNAME,
                      label: item.AUTHORNAME,
                    }))}
                    value={{ value: Authorname, label: Authorname }}
                  />
                </div>
              </div>
            </main>
            {isSmallLoading && (
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, 0%)", // Center the element
                  width: "70%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <UploadingSpinner />
              </div>
            )}

            <div className={isScreenBlock ? "blocked-screen" : ""}></div>
            <main className="main__admin my-[20px]">
              <div>
                <h1>
                  VIDEO UPLOADED <span style={{ color: "red " }}>*</span>
                </h1>
                <label className="inlineblock px-2 rounded bg-violet-200 cursur-pointer">
                  choose file
                  <input
                    className="hidden"
                    type="file"
                    id="myFile"
                    onChange={HandleURLupload}
                    disabled={VideoUrllink !== "" ? true : false}
                  />
                </label>
                {selectedFile && (
                  <div className="flex justify-between px-1">
                    <div>{selectedFile.name}</div>
                    <button onClick={handleFileDelete} className="text-lg">
                      <RiDeleteBin2Fill />
                    </button>
                  </div>
                )}
              </div>

              <div>
                <h1>
                  VIDEO URL <span style={{ color: "red " }}>*</span>
                </h1>
                <input
                  className="p-[2px] text-violet-800"
                  value={VideoUrllink}
                  type="text"
                  id="myFile"
                  onChange={HandleURLlink}
                  disabled={selectedFile !== null ? true : false}
                />
              </div>
            </main>

            <main className="main__admin my-[20px]">
              <div>
                <h1>
                  VIDEO BRIEF <span style={{ color: "red " }}>*</span>
                </h1>
                <textarea
                  type="text"
                  value={VideoBrief}
                  onChange={(e) => setVideoBrief(e.target.value)}
                />
              </div>
            </main>

            {/* <div>
        <aside>
          <table class="custom-table">
            <thead class="header-cell">
              <th>MODULE ID</th>
              <th>MODULE NAME</th>
              <th>MODULE NARRATION</th>
           
              <th>EDIT</th>
            </thead>
            <tbody>
              {ModuleDetails.map((item, index) => (
                <tr key={index} class="table-row">
                  <td>{item.MODULEID}</td>
                  <td>{item.MODULENAME}</td>
                  <td>{item.MODULENARRATION}</td>
                
                </tr>
              ))}
            </tbody>
          </table>
        </aside>
      </div> */}
          </aside>{" "}
          <aside>
            {/* <table>
              <thead></thead>
              <tbody style={{ alignItems: "center", textAlign: "center" }}>
                {VideoDetails.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {item.VIDEOUPLOADED && (
                        <ReactPlayer
                          url={createBlobURL(item.VIDEOUPLOADED.data)}
                          controls
                          width="320"
                          height="240"
                          onError={(e) =>
                            console.error("Error playing video:", e)
                          }
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table> */}
          </aside>
          <aside className="w-[80%] mr-5">
            {" "}
            <header className="header__of__main flex justify-between mr-5">
              <h1 className="header__of__page">LIST OF VIDEOS</h1>
            </header>
            <section className="lg:flex gap-2 items-center ">
              <div className="searchhhhhh">
                <p>Search </p>
                <input
                  className="input_1"
                  required
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />

                <div className="search__icon">
                  <FaSearch />
                </div>
              </div>
              <div className="lg:flex gap-2 items-center ">
                <p>Module</p>
                <Select
                  options={modules}
                  onChange={setSelectedModule}
                  placeholder="Select Module"
                  isClearable
                  className="lg:ml-2 lg:w-[350px]"
                />
              </div>
            </section>
            <aside
              className="
             table__video__app"
            >
              <table class="custom-table ">
                <thead class="header-cell">
                  <td>VIDEO&nbsp;NAME</td>
                  <td>MODULE&nbsp;NAME</td>
                  <td>CHAPTER&nbsp;NAME</td>
                  <td>AUTHOR&nbsp;NAME</td>
                  {/* <td>CREATED&nbsp;BY</td>
                <td>CREATED&nbsp;DATE</td> */}
                  <td>APPROVAL&nbsp;STATUS</td>
                  <td>EDIT</td>
                </thead>
                <tbody>
                  {filteredData.map((item, index) => (
                    <tr
                      key={index}
                      onClick={() => handleRowClick(item.VIDEOID)}
                      className="cursor__video__list"
                    >
                      <td>{item.VIDEONAME ? item.VIDEONAME : "N/A"}</td>
                      <td>{item.MODULENAME ? item.MODULENAME : "N/A"}</td>
                      <td>{item.CHAPTERNAME ? item.CHAPTERNAME : "N/A"}</td>
                      <td>{item.AUTHORNAME ? item.AUTHORNAME : "N/A"}</td>
                      {/* <td>{item.CREATEDBY ? item.CREATEDBY : "N/A"}</td>

                    <td>
                      {item.CREATEDDATE
                        ? new Date(item.CREATEDDATE).toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )
                        : "N/A"}
                    </td> */}
                      <td>
                        {item.APPROVALSTATUS ? item.APPROVALSTATUS : "N/A"}
                      </td>
                      <td
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                          fontSize: "22px",
                          textAlign: "right",
                        }}
                        onClick={() => {
                          FetchTableValuesInFields(item);
                          scrollToSection();
                        }}
                      >
                        <BiSolidEditAlt />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
            {isSmallLoading && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoadingSmall />
              </div>
            )}
            <div className={isScreenBlock ? "blocked-screen" : ""}>
              {isPopupVisible && (
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(0, 0, 0, 0.3)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#fff",
                      padding: "10px",
                      borderRadius: "8px",
                      boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                      maxWidth: "800px",
                      minHeight: "80vh",
                      width: "100%",
                    }}
                  >
                    {isSmallLoading && (
                      <div className="flex justify-center items-center z-50">
                        <SmallLoading />
                      </div>
                    )}
                    <div className="flex justify-between mb-2 w-[100%] px-[3%] font-bold lg:text-[25px] items-center uppercase">
                      {Videoplayer.map((item, index) => (
                        <div className="flex justify-between items-center w-[100%]">
                          <section key={index}>{item.VIDEONAME}</section>
                          <div className="flex justify-between items-center">
                            <button
                              onClick={() => {
                                approveVideo(item.VIDEOID, item.APPROVALSTATUS);
                              }}
                              className="bg-violet-700 text-white py-1 px-3 lg:block hidden rounded hover:shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] text-[16px] mx-2"
                            >
                              {" "}
                              APPROVE
                            </button>
                            <button
                              onClick={closePopup}
                              className="bg-yellow-700 text-white py-1 px-3 lg:block hidden rounded hover:shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] text-[16px] mx-2"
                            >
                              {" "}
                              EDIT
                            </button>
                            <button
                              onClick={() => {
                                deleteVideo(item.VIDEOID);
                              }}
                              className="bg-red-700 text-white py-1 px-3 lg:block hidden rounded hover:shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] text-[16px] mx-2"
                            >
                              {" "}
                              DELETE
                            </button>
                            <button
                              onClick={() => {
                                approveVideo(item.VIDEOID, item.APPROVALSTATUS);
                              }}
                              className="bg-violet-700 text-white p-1 block lg:hidden rounded hover:shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] text-[16px] mx-2"
                            >
                              {" "}
                              <TiTickOutline />
                            </button>
                            <div
                              onClick={closePopup}
                              style={{
                                cursor: "pointer",
                                fontSize: "25px",
                                font: 600,
                                color: "black",
                              }}
                              className="flex items-center"
                            >
                              <div>
                                {" "}
                                <IoMdClose />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="overflow-auto">
                      {" "}
                      {Videoplayer.map((item, index) => (
                        <section key={index}>
                          <div className="flex justify-center">
                            {item.VIDEOURL && (
                              <ReactPlayer
                                ref={playerRef}
                                url={item?.VIDEOURL}
                                controls
                                width="95%"
                                maxHeight="240"
                                onReady={handlePlayerReady}
                                onError={(e) =>
                                  console.error("Error playing video:", e)
                                }
                              />
                            )}
                            {item.VIDEOUPLOADED && (
                              <ReactPlayer
                                ref={playerRef}
                                url={
                                  item.VIDEOUPLOADED !== null
                                    ? item.VIDEOUPLOADED
                                    : item.VIDEOURL
                                }
                                controls
                                width="95%"
                                maxHeight="240"
                                onReady={handlePlayerReady}
                                onError={(e) =>
                                  console.error("Error playing video:", e)
                                }
                              />
                            )}
                          </div>
                          <div className="p-[3%] uppercase video__brief">
                            <aside>
                              <p>VIDEO NAME :</p>
                              <h1>{item.VIDEONAME ? item.VIDEONAME : "N/A"}</h1>
                            </aside>
                            <aside>
                              {" "}
                              <p>APPROVAL STATUS : </p>
                              <h1>
                                {" "}
                                {item.APPROVALSTATUS
                                  ? item.APPROVALSTATUS
                                  : "N/A"}
                              </h1>
                            </aside>
                            <aside>
                              {" "}
                              <p>CREATED DATE : </p>
                              <h1>
                                {" "}
                                {item.CREATEDDATE
                                  ? new Date(
                                      item.CREATEDDATE
                                    ).toLocaleDateString("en-GB", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    })
                                  : "N/A"}{" "}
                              </h1>
                            </aside>
                            <aside>
                              {" "}
                              <p>CREATED BY :</p>
                              <h1>{item.CREATEDBY ? item.CREATEDBY : "N/A"}</h1>
                            </aside>
                            <aside>
                              <p> VIDEO BRIEF : </p>
                              <h1>
                                {" "}
                                {item.VIDEOBRIEF ? item.VIDEOBRIEF : "N/A"}
                              </h1>
                            </aside>
                          </div>
                        </section>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </aside>
        </aside>
      </section>
    </div>
  );
}

export default Video;
