const initialState = {};
const selectStatements = (state = initialState, action) => {
  switch (action.type) {
    case "PASSSTATEMENTS":
      return action.data;

    default:
      return state;
  }
};

export default selectStatements;