import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import "../Modules/AdminDashboard.css";
import LoadingSpinner from "../../Components/Loading/Loading";
import MenuNavbar from "../../Components/Navbar/MenuNavbar";
import axios from "axios";
import { FaSearch } from "react-icons/fa";
import SideBar from "../../Components/SideBar/SideBar";
const options = [
  { value: "masters", label: "Masters" },
  { value: "transactions", label: "Transactions" },
  { value: "queries", label: "Queries" },
  { value: "reports", label: "Reports" },
];

const Modules = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [selectedOption, setSelectedOption] = useState(null);
  const [ModuleId, setModuleId] = useState("");
  const [ModuleDetails, setModuleDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [Modulename, setModulename] = useState("");
  const [ModuleNarrration, setModuleNarrration] = useState("");
  const [search, setSearch] = useState("");
  const [modules, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const state = useSelector((state) => state.changeTheState);
  console.log(state.user.userCode);
  const createdby = state.user.userCode;
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  function ModuleInterface() {
    setIsLoading(true);
    axios
      .get(`${BASEURL}/getModuleMaster`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setModuleDetails(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${BASEURL}/getNewModuleId`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        console.log(response.data[0]);
        setModuleId(response.data[0]);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    ModuleInterface();

    fetchModules(); // Add this line
  }, []);

  const fetchModules = () => {
    axios
      .get(`${BASEURL}/getModuleMaster`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        const moduleOptions = response.data.map((module) => ({
          value: module.MODULENAME,
          label: module.MODULENAME,
        }));
        setModules(moduleOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function handleSave() {
    setIsLoading(true);
    console.log(ModuleId.MODULEID);
    console.log(ModuleNarrration);
    if (!Modulename || !ModuleNarrration) {
      alert("Enter the Required Details");
      setIsLoading(false);
    } else {
      axios
        .post(
          `${BASEURL}/insertmodules`,
          {
            MODULEID: ModuleId.MODULEID ? ModuleId.MODULEID : ModuleId,
            MODULENAME: Modulename,
            MODULENARRATION: ModuleNarrration,
            MODULECREATEDBY: createdby,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          console.log(response.data.message);
          alert(response.data.message);
          setIsLoading(false);
          window.location.reload();
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            alert(error.response.data.message);
          } else {
            console.error("Error:", error);
            alert("An error occurred");
          }

          setIsLoading(false);
        });
    }
  }

  function handleClick(item) {
    setModulename(item.MODULENAME);
    setModuleNarrration(item.MODULENARRATION);
    setModuleId(item.MODULEID);
  }
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "100px",
      marginTop: "0px",
      minHeight: "initial",
      height: "45px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const filteredData = ModuleDetails.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0 && !selectedModule) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["MODULEID", "MODULENAME", "MODULENARRATION", "CREATEDBY"].some(
        (key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        }
      );
    });

    const moduleMatch = selectedModule
      ? item.MODULENAME === selectedModule.value
      : true;

    return textMatch && moduleMatch;
  });
  if (isLoading) {
    return <LoadingSpinner />;
  }
  function refresh() {
    window.location.reload();
  }
  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="">
      <MenuNavbar />
      <section className="flex all__page__main__section">
        <aside className="w-[20%]">
          <SideBar />
        </aside>
        <aside className="w-[80%]">
          {" "}
          <header className="header__of__main flex justify-between mr-5">
            <h1 className="header__of__page">Module Master</h1>
            <div className="save_btn">
              <button
                className="button_style"
                type="submit"
                onClick={handleSave}
              >
                Save
              </button>
              <button className="button_style" type="submit" onClick={refresh}>
                New Record
              </button>
            </div>
          </header>
          <aside className="divission__">
            <main className="main__admin my-[20px] ">
              <div>
                <h1 className="md:py-5 md:w-[300px] ">
                  MODULE ID &nbsp; : &nbsp;
                  <b style={{ color: "red " }}>
                    {ModuleId.MODULEID ? ModuleId.MODULEID : ModuleId}
                    {/* 100000000000000 */}
                  </b>
                </h1>
              </div>
              <div>
                <h1>
                  MODULE NAME&nbsp;<span style={{ color: "red " }}>*</span>
                </h1>

                <input
                  value={Modulename}
                  type="text"
                  onChange={(e) => setModulename(e.target.value)}
                />
              </div>
            </main>
            <main className="main__admin my-[20px]">
              <div>
                <h1>
                  MODULE NARRATON <span style={{ color: "red " }}>*</span>
                </h1>

                <textarea
                  value={ModuleNarrration}
                  type="text"
                  onChange={(e) => setModuleNarrration(e.target.value)}
                ></textarea>
              </div>
            </main>
          </aside>
          <div>
            <section className="lg:flex gap-2 items-center ">
              <div className="searchhhhhh">
                <p>Search </p>
                <input
                  className="input_1"
                  required
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />

                <div className="search__icon">
                  <FaSearch />
                </div>
              </div>
              <div className="lg:flex gap-2 items-center ">
                <p>Module</p>
                <Select
                  options={modules}
                  onChange={setSelectedModule}
                  placeholder="Select Module"
                  isClearable
                  className="lg:ml-2 lg:w-[350px]"
                />
              </div>
            </section>
            <aside
              className="
             table__video__app"
            >
              <table class="custom-table">
                <thead class="header-cell">
                  <td>MODULE&nbsp;ID</td>
                  <td>MODULE&nbsp;NAME</td>
                  <td>MODULE&nbsp;NARRATION</td>

                  <td>EDIT</td>
                </thead>
                <tbody>
                  {filteredData.map((item, index) => (
                    <tr key={index} class="table-row">
                      <td className="alignleft">
                        {item.MODULEID ? item.MODULEID : "N/A"}
                      </td>
                      <td>{item.MODULENAME ? item.MODULENAME : "N/A"}</td>
                      <td style={{ maxWidth: "400px" }}>
                        {item.MODULENARRATION ? item.MODULENARRATION : "N/A"}
                      </td>

                      <td
                        onClick={(e) => {
                          handleClick(item);
                          scrollToSection();
                        }}
                      >
                        <FaRegEdit />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </div>
        </aside>{" "}
      </section>
    </div>
  );
};

export default Modules;
