import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "../ListofVideo/ListofVideo.css";
import MenuNavbar from "../../Components/Navbar/MenuNavbar";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../../Components/SideBar/SideBar";
import ReactPlayer from "react-player";
import { IoMdClose } from "react-icons/io";
import SmallLoading from "../../Components/Loading/LoadingSmall";
import LoadingSmall from "../../Components/Loading/LoadingSmall";
import { FaSearch } from "react-icons/fa";
import LoadingSpinner from "../../Components/Loading/Loading";
import { TiTickOutline } from "react-icons/ti";
import Select from "react-select";

function ListofVideo() {
  const playerRef = useRef(null);

  const handlePlayerReady = () => {
    const videoElement = playerRef.current.getInternalPlayer();
    if (videoElement) {
      videoElement.setAttribute("controlsList", "nodownload");
    }
  };
  const [VideoDetails, setVideoDetails] = useState([]);
  const [Videoplayer, setVideoplayer] = useState([]);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isScreenBlock, setScreenBlock] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [isSmallLoading, setSmallLoading] = useState(false);
  const [modules, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);

  const getuserRights = useSelector((state) => state.changeTheState);
  const userRights = getuserRights?.user?.userType;
  const userPassedBY = getuserRights?.user?.userCode;
  const [search, setSearch] = useState("");
  console.log(userPassedBY, "getuserRights");
  function videodata() {
    setisloading(true);

    axios
      .get(`${BASEURL}/getAllVideosfortable`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setVideoDetails(response.data);
        setisloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    videodata();
    fetchModules(); // Add this line
  }, []);

  const fetchModules = () => {
    axios
      .get(`${BASEURL}/getModuleMaster`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        const moduleOptions = response.data.map((module) => ({
          value: module.MODULENAME,
          label: module.MODULENAME,
        }));
        setModules(moduleOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRowClick = (videoid) => {
    setScreenBlock(true);
    setSelectedVideo(videoid);
    setSmallLoading(true);
    axios
      .post(
        `${BASEURL}/getVideoDetails`,
        {
          VIDEOID: videoid,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setVideoplayer(response.data);
        console.log(response.data, "setVideoplayer");
        setPopupVisible(true);
        setSmallLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const closePopup = () => {
    setScreenBlock(false);
    setPopupVisible(false);
  };

  const createBlobURL = (uint8Array) => {
    const buffer = new Uint8Array(uint8Array);
    const file = new File([buffer], "video.mp4", { type: "video/mp4" });
    return URL.createObjectURL(file);
  };
  // if (isSmallLoading) {
  //   return <SmallLoading />;
  // }
  function approveVideo(videoid, approvalStatus) {
    const confirm = window.confirm(
      "Are you sure you want to Approve this video"
    );
    if (!confirm) {
      console.log("video rejected by user");
      return;
    }
    console.log(videoid, approvalStatus, userRights, userPassedBY);
    if (confirm) {
      if (approvalStatus === "APPROVED") {
        alert("video already approved");
      }
      //  else if (userRights === false) {
      //   alert("user dont have the rights to approve the video");
      // }
      else {
        axios
          .post(
            `${BASEURL}/videoapproval`,
            {
              VIDEOID: videoid,
              STATUSUPDATEBY: userPassedBY,
              APPROVEDSTATUS: "APPROVED",
            },
            {
              headers: {
                "auth-token": authToken,
              },
            }
          )
          .then((response) => {
            console.log(response);
            alert(response.data.message);
          })
          .catch((err) => {
            console.log(err);
          });
        console.log(
          videoid,
          approvalStatus,
          userRights,
          userPassedBY,
          "videoapproval"
        );
      }
    }
  }
  const filteredData = VideoDetails.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0 && !selectedModule) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["VIDEONAME", "MODULENAME", "CHAPTERNAME", "AUTHORNAME"].some(
        (key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        }
      );
    });

    const moduleMatch = selectedModule
      ? item.MODULENAME === selectedModule.value
      : true;

    return textMatch && moduleMatch;
  });

  if (isloading) {
    return <LoadingSpinner />;
  }
  return (
    <div>
      <MenuNavbar />
      <section className="flex all__page__main__section">
        <aside className="w-[20%]">
          <SideBar />
        </aside>
        <aside className="w-[80%]">
          {" "}
          <header className="header__of__main flex justify-between mr-5">
            <h1 className="header__of__page">LIST OF VIDEOS</h1>
          </header>
          <section className="lg:flex gap-2 items-center ">
            <div className="searchhhhhh">
              <p>Search </p>
              <input
                className="input_1"
                required
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />

              <div className="search__icon">
                <FaSearch />
              </div>
            </div>
            <div className="lg:flex gap-2 items-center ">
              <p>Module</p>
              <Select
                options={modules}
                onChange={setSelectedModule}
                placeholder="Select Module"
                isClearable
                className="lg:ml-2 lg:w-[350px]"
              />
            </div>
          </section>
          <aside
            className="
             table__video__app"
          >
            <table class="custom-table ">
              <thead class="header-cell">
                <td>VIDEO&nbsp;NAME</td>
                <td>MODULE&nbsp;NAME</td>
                <td>CHAPTER&nbsp;NAME</td>
                <td>AUTHOR&nbsp;NAME</td>
                {/* <td>CREATED&nbsp;BY</td>
                <td>CREATED&nbsp;DATE</td> */}
                <td>APPROVAL&nbsp;STATUS</td>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr
                    key={index}
                    onClick={() => handleRowClick(item.VIDEOID)}
                    className="cursor__video__list"
                  >
                    <td>{item.VIDEONAME ? item.VIDEONAME : "N/A"}</td>
                    <td>{item.MODULENAME ? item.MODULENAME : "N/A"}</td>
                    <td>{item.CHAPTERNAME ? item.CHAPTERNAME : "N/A"}</td>
                    <td>{item.AUTHORNAME ? item.AUTHORNAME : "N/A"}</td>
                    {/* <td>{item.CREATEDBY ? item.CREATEDBY : "N/A"}</td>

                    <td>
                      {item.CREATEDDATE
                        ? new Date(item.CREATEDDATE).toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )
                        : "N/A"}
                    </td> */}
                    <td>{item.APPROVALSTATUS ? item.APPROVALSTATUS : "N/A"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </aside>
        </aside>
      </section>

      {isSmallLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingSmall />
        </div>
      )}
      <div className={isScreenBlock ? "blocked-screen" : ""}>
        {isPopupVisible && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "rgba(0, 0, 0, 0.3)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                padding: "10px",
                borderRadius: "8px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                maxWidth: "800px",
                minHeight: "80vh",
                width: "100%",
              }}
            >
              {isSmallLoading && (
                <div className="flex justify-center items-center z-50">
                  <SmallLoading />
                </div>
              )}
              <div className="flex justify-between mb-2 w-[100%] px-[3%] font-bold lg:text-[25px] items-center uppercase">
                {Videoplayer.map((item, index) => (
                  <div className="flex justify-between items-center w-[100%]">
                    <section key={index}>{item.VIDEONAME}</section>
                    <div className="flex justify-between items-center">
                      <button
                        onClick={() => {
                          approveVideo(item.VIDEOID, item.APPROVALSTATUS);
                        }}
                        className="bg-violet-700 text-white py-1 px-3 lg:block hidden rounded hover:shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] text-[16px] mx-2"
                      >
                        {" "}
                        APPROVE
                      </button>
                      <button
                        onClick={() => {
                          approveVideo(item.VIDEOID, item.APPROVALSTATUS);
                        }}
                        className="bg-violet-700 text-white p-1 block lg:hidden rounded hover:shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] text-[16px] mx-2"
                      >
                        {" "}
                        <TiTickOutline />
                      </button>
                      <div
                        onClick={closePopup}
                        style={{
                          cursor: "pointer",
                          fontSize: "25px",
                          font: 600,
                          color: "black",
                        }}
                        className="flex items-center"
                      >
                        <div>
                          {" "}
                          <IoMdClose />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="overflow-auto">
                {" "}
                {Videoplayer.map((item, index) => (
                  <section key={index}>
                    <div className="flex justify-center">
                      {item.VIDEOURL && (
                        <ReactPlayer
                          ref={playerRef}
                          url={item?.VIDEOURL}
                          controls
                          width="95%"
                          maxHeight="240"
                          onReady={handlePlayerReady}
                          onError={(e) =>
                            console.error("Error playing video:", e)
                          }
                        />
                      )}
                      {item.VIDEOUPLOADED && (
                        <ReactPlayer
                          ref={playerRef}
                          url={
                            item.VIDEOUPLOADED !== null
                              ? item.VIDEOUPLOADED
                              : item.VIDEOURL
                          }
                          controls
                          width="95%"
                          maxHeight="240"
                          onReady={handlePlayerReady}
                          onError={(e) =>
                            console.error("Error playing video:", e)
                          }
                        />
                      )}
                    </div>
                    <div className="p-[3%] uppercase video__brief">
                      <aside>
                        <p>VIDEO NAME :</p>
                        <h1>{item.VIDEONAME ? item.VIDEONAME : "N/A"}</h1>
                      </aside>
                      <aside>
                        {" "}
                        <p>APPROVAL STATUS : </p>
                        <h1>
                          {" "}
                          {item.APPROVALSTATUS ? item.APPROVALSTATUS : "N/A"}
                        </h1>
                      </aside>
                      <aside>
                        {" "}
                        <p>CREATED DATE : </p>
                        <h1>
                          {" "}
                          {item.CREATEDDATE
                            ? new Date(item.CREATEDDATE).toLocaleDateString(
                                "en-GB",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                }
                              )
                            : "N/A"}{" "}
                        </h1>
                      </aside>
                      <aside>
                        {" "}
                        <p>CREATED BY :</p>
                        <h1>{item.CREATEDBY ? item.CREATEDBY : "N/A"}</h1>
                      </aside>
                      <aside>
                        <p> VIDEO BRIEF : </p>
                        <h1> {item.VIDEOBRIEF ? item.VIDEOBRIEF : "N/A"}</h1>
                      </aside>
                    </div>
                  </section>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ListofVideo;
