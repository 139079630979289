import React, { useEffect } from "react";
import "./css/scroll.css";
import scroll1 from "../Components/images/video1.PNG";
import scroll2 from "../Components/images/video2.PNG";
import scroll3 from "../Components/images/video3.PNG";
import scroll4 from "../Components/images/video4.PNG";

import scroll6 from "../Components/images/video6.PNG";
import scroll7 from "../Components/images/video7.PNG";
import scroll8 from "../Components/images/video8.PNG";
import scroll9 from "../Components/images/video9.jpg";
const Scroll2 = () => {
  useEffect(() => {
    const scrollers = document.querySelectorAll(".scroller");

    // Function to add animation
    const addAnimation = () => {
      scrollers.forEach((scroller) => {
        scroller.setAttribute("data-animated", true);

        const scrollerInner = scroller.querySelector(".scroller__inner");
        const scrollerContent = Array.from(scrollerInner.children);
        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true);
          duplicatedItem.setAttribute("aria-hidden", true);
          scrollerInner.appendChild(duplicatedItem);
        });
      });
    };

    // Check if the user hasn't opted in for reduced motion
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }
  }, []); // Empty dependency array to run the effect only once on mount

  return (
    <div className="">
      <h1 style={{ textAlign: "center" }}></h1>

      <div className="scroller" data-direction="right" data-speed="slow">
        <div className="scroller__inner">
          <img height={200} width={300} src={scroll1} />
          <img height={200} width={300} src={scroll2} />
          <img height={200} width={300} src={scroll3} />
          <img height={200} width={300} src={scroll4} />
          <img height={200} width={300} src={scroll6} />
          <img height={200} width={300} src={scroll7} />
          <img height={200} width={300} src={scroll8} />
          <img height={200} width={300} src={scroll9} />
        </div>
      </div>
    </div>
  );
};

export default Scroll2;
