import React from "react";
import { Link } from "react-router-dom";
import Scroll from "../Components/Scroll";
import NavbarMain from "../Components/NavbarMain";
import Scroll2 from "../Components/Scroll2";
import Trust1 from "../Components/images/trust1.PNG";
import Trust2 from "../Components/images/trust2.PNG";
import InfiniteCardSlider from "../Components/Advertising/InfiniteCardSlider";
import Footer from "../Components/Footer/Footer";
const LandingPage = () => {
  return (
    <div>
      <NavbarMain />
      <section className="h-[77vh] flex flex-col justify-center items-center bg-gradient-to-b from-blue-100 to-white text-violet-900">
        <div className="text-center mt-[50px]">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            Welcome to Our Video Tutorial
          </h1>
          <Link to="/Login">
            <a
              href="#tutorial"
              className=" bg-white text-indigo-600 py-2 px-6 rounded-full text-lg md:text-xl font-semibold transition duration-300 hover:bg-indigo-600 hover:text-white shadow-md"
            >
              Get Started
            </a>
          </Link>
        </div>

        {/* <h1 className=" text-[25px]  font-bold mt-[50px]">Trusted By</h1>
        <picture className="picture__trust flex justify-center text-center flex-col md:flex-row">
          <img src={Trust1} alt="" className="h-[80px] mix-blend-darken" />
          <img src={Trust2} alt="" className="h-[80px] mix-blend-darken" />
        </picture> */}
      </section>
      <Scroll />
      <div className="mt-10">
        {" "}
        <div className="text-center py-8">
          <h1 className="text-4xl md:text-6xl font-bold text-gray-800">
            Learn. Grow. Succeed.
          </h1>
          <p className="mt-4 text-lg md:text-xl text-gray-600">
            Enhance your skills and broaden your knowledge through our
            comprehensive tutorials, tailored to fuel your success journey.
          </p>
        </div>
      </div>

      <InfiniteCardSlider />
      <div className="mt-10">
        <div className="text-center py-8">
          <h2 className="text-3xl md:text-5xl font-bold text-green-800 mt-8 mb-6">
            Unleash Innovation and Creativity
          </h2>
          <p className="text-lg md:text-xl text-gray-800 leading-relaxed">
            Explore innovative concepts and foster your creative potential with
            tutorials designed to inspire and encourage ingenuity.
          </p>
        </div>
      </div>
      <Scroll2 />
    </div>
  );
};

export default LandingPage;
