import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import Select from "react-select";
import SideBar from "../../Components/SideBar/SideBar";
import LoadingSpinner from "../../Components/Loading/Loading";
import axios from "axios";
import { FaSearch } from "react-icons/fa";
import MenuNavbar from "../../Components/Navbar/MenuNavbar";
import { useDispatch, useSelector } from "react-redux";
function Area() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [AreaDetails, setAreaDetails] = useState([]);
  const [AreaId, setAreaId] = useState("");
  const [AreaCreatedBy, setAreaCreatedBy] = useState("");
  const [AreaName, setAreaName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");

  const state = useSelector((state) => state.changeTheState);
  console.log(state.user.userCode);

  const createdby = state.user.userCode;
  function AreaInterface() {
    setIsLoading(true);
    axios
      .get(`${BASEURL}/getAreas`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setAreaDetails(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${BASEURL}/getNewAreaId`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setAreaId(response.data[0].AREAID);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    AreaInterface();
  }, []);
  function HandleSave() {
    setIsLoading(true);
    if (!AreaName) {
      alert("Fill all the Details");
    } else {
      axios
        .post(
          `${BASEURL}/insertareas`,
          {
            AREAID: AreaId,
            AREANAME: AreaName,
            AREACREATEDBY: createdby,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          console.log(response);
          alert(response.data.message);
          setIsLoading(false);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function HandeEditChanges(item) {
    setAreaId(item.AREAID);
    setAreaName(item.AREANAME);
    setAreaCreatedBy(item.OM_CREATED_BY);
  }

  const filteredData = AreaDetails.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["AREAID", "AREANAME", "OM_CREATED_BY"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });
  if (isLoading) {
    return <LoadingSpinner />;
  }
  function refresh() {
    window.location.reload();
  }

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="">
      <MenuNavbar />
      <section className="flex all__page__main__section">
        <aside className="w-[20%]">
          <SideBar />
        </aside>
        <aside className="w-[80%]">
          {" "}
          <header className="header__of__main flex justify-between mr-5">
            <h1 className="header__of__page">AREAS Master</h1>
            <div className="save_btn">
              <button
                className="button_style"
                type="submit"
                onClick={HandleSave}
              >
                Save
              </button>
              <button className="button_style" type="submit" onClick={refresh}>
                New Record
              </button>
            </div>
          </header>
          <main className="main__admin my-[20px]">
            <div>
              <h1 className="w-[180px] mt-5">
                AREA ID &nbsp; : &nbsp;
                <b style={{ color: "red " }}>{AreaId}</b>
              </h1>
            </div>
            <div>
              <h1 className="w-[180px]">AREA NAME</h1>
              <input
                type="text"
                value={AreaName}
                onChange={(e) => setAreaName(e.target.value)}
              />
            </div>
          </main>
          {/* <main className="main__admin m-[20px]">
        <div>
          <h1 className="w-[180px]">AREA CREATED BY</h1>
          <input
            type="text"
            value={AreaCreatedBy}
            onChange={(e) => setAreaCreatedBy(e.target.value)}
          />
        </div>

      </main> */}
          <div>
            <div className="searchhhhhh">
              <p>Search:</p>
              <input
                className="input_1"
                required
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              ></input>
              <div className="search__icon">
                {" "}
                <FaSearch />
              </div>
            </div>
            <aside className="table__video__app uppercase">
              <table class="custom-table">
                <thead class="header-cell">
                  <td>AREA&nbsp;ID</td>
                  <td>AREA&nbsp;NAME</td>

                  <td>EDIT</td>
                </thead>
                <tbody>
                  {filteredData.map((item, index) => (
                    <tr key={index} class="table-row">
                      <td className="alignleft">
                        {item.AREAID ? item.AREAID : "N/A"}
                      </td>
                      <td>{item.AREANAME ? item.AREANAME : "N/A"}</td>

                      <td
                        onClick={(e) => {
                          HandeEditChanges(item);
                          scrollToSection();
                        }}
                      >
                        {" "}
                        <FaRegEdit />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </div>
        </aside>
      </section>
    </div>
  );
}

export default Area;
