import { useEffect, useState } from "react";
import React from "react";
import axios from "axios";
import Select from "react-select";
import { FaRegEdit } from "react-icons/fa";
import MenuNavbar from "../../Components/Navbar/MenuNavbar";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../../Components/SideBar/SideBar";
import LoadingSpinner from "../../Components/Loading/Loading";
import { FaSearch } from "react-icons/fa";

const ChapterList = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [chapterListDetail, setChapterListDetail] = useState([]);
  const [newChapterId, setGetNewChapterId] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [FetchModulename, setFetchModulename] = useState([]);
  const [SelectedModuleId, setSelectedModuleId] = useState("");
  const [ModuleNamemapped, setModuleNamemapped] = useState("");
  const [ChapterName, setChapterName] = useState("");
  const [ChapterNarration, setChapterNarration] = useState("");
  const [AreaDetails, setAreaDetails] = useState([]);
  const [search, setSearch] = useState("");
  const [chapterSelect, setchapterSelect] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState(null);

  const [AreaNames, setAreaNames] = useState([]);
  const [AreaId, setAreaId] = useState([]);
  const state = useSelector((state) => state.changeTheState);
  console.log(state.user.userCode);
  const createdby = state.user.userCode;
  function getId() {
    setisloading(true);
    axios
      .get(`${BASEURL}/getNewChapterId`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setGetNewChapterId(response.data[0].CHAPTERID);
        console.log(response.data[0].CHAPTERID);
        setisloading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${BASEURL}/getModuleMaster`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setFetchModulename(response.data);
        console.log(response.data);
        setisloading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${BASEURL}/getAreas`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setAreaDetails(response.data);
        setisloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    getId();
  }, []);

  // const toGetTheChpeter = () => {};
  function HandleSelectedModule(name) {
    setModuleNamemapped(name);
    const ModuleName = FetchModulename.find(
      (item) => item.MODULENAME === name.value
    );
    setSelectedModuleId(ModuleName ? ModuleName.MODULEID : "");
    setChapterName("");
    setChapterNarration("");

    axios
      .post(
        `${BASEURL}/getChapterlist`,
        {
          MODULEID: ModuleName.MODULEID,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setChapterListDetail(response.data);
        console.log(response.data, "dattt");
        setisloading(false);
        const chapterOptions = response.data.map((chapter) => ({
          value: chapter.CHAPTERNAME,
          label: chapter.CHAPTERNAME,
        }));
        setchapterSelect(chapterOptions);
      })
      .catch((error) => {
        console.error("Error fetching List Of getChapterlist:", error);
        setisloading(false);
      });
  }

  function HandleArea(selectedvalue) {
    const selectedValueLabels = selectedvalue.map((item) => item.value);
    setAreaNames([...selectedValueLabels]);
    const AreaId = selectedValueLabels.map((itemS) => {
      console.log(itemS);
      const area = AreaDetails.find((item) => item.AREANAME === itemS);
      console.log(area);
      return area.AREAID;
    });
    setAreaId(AreaId);
    console.log(AreaId);
  }
  async function handleSave() {
    setisloading(true);
    if (!ChapterName || !ChapterNarration || !SelectedModuleId) {
      alert("Fill all the Details");
      setisloading(false);
    } else {
      console.log(SelectedModuleId);
      console.log(newChapterId);
      console.log(ChapterName);
      console.log(ChapterNarration);
      console.log(createdby);
      console.log("AreaId:", AreaId);

      try {
        for (let i = 0; i < AreaId.length; i++) {
          const response = await axios.post(
            `${BASEURL}/insertchapters`,
            {
              MODULEID: SelectedModuleId,
              CHAPTERID: newChapterId,
              CHAPTERNAME: ChapterName,
              CHAPTERNARRATION: ChapterNarration,
              CHAPTERCREATEDBY: createdby,
              AREAID: AreaId[i],
            },
            {
              headers: {
                "auth-token": authToken,
              },
            }
          );

          console.log(response.data);
          alert(response.data.message);
        }

        setisloading(false);
        window.location.reload();
      } catch (error) {
        console.error("Error inserting chapters:", error);
        setisloading(false);
      }
    }
  }
  function handleClick(item) {
    setChapterName(item.CHAPTERNAME);
    setChapterNarration(item.CHAPTERNARRATION);
    setGetNewChapterId(item.CHAPTERID);
  }

  function refresh() {
    window.location.reload();
  }
  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const filteredData = chapterListDetail.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0 && !selectedChapter) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["MODULEID", "CHAPTERID", "CHAPTERNAME", "CHAPTERNARRATION"].some(
        (key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        }
      );
    });

    const moduleMatch = selectedChapter
      ? item.CHAPTERNAME === selectedChapter.value
      : true;

    return textMatch && moduleMatch;
  });
  if (isloading) {
    return <LoadingSpinner />;
  }
  return (
    <div>
      <MenuNavbar />
      <section className="flex all__page__main__section">
        <aside className="w-[20%]">
          <SideBar />
        </aside>
        <aside className="w-[80%]">
          {" "}
          <header className="header__of__main flex justify-between mr-5">
            <h1 className="header__of__page">Chapters Master</h1>
            <div className="save_btn">
              <button
                className="button_style"
                type="submit"
                onClick={handleSave}
              >
                Save
              </button>
              <button className="button_style" type="submit" onClick={refresh}>
                New Record
              </button>
            </div>
          </header>
          <aside>
            <main className="main__admin my-[20px] flex gap-[30px]">
              <div>
                <h1 className="w-[180px]">
                  Chapter Id &nbsp; : &nbsp;
                  {/* <input type="text" value={newChapterId} /> */}
                  <b style={{ color: "red " }}>
                    {newChapterId}
                    {/* 100000000000000 */}
                  </b>
                </h1>
              </div>
            </main>
            <main className="main__admin my-[20px]">
              <div>
                <h1 className="w-[180px]">
                  {" "}
                  Module Name <span style={{ color: "red " }}>*</span>
                </h1>
                <div style={{ width: "300px", margin: "5px", zIndex: 100 }}>
                  <Select
                    // value={{}}
                    placeholder="Select a module name..."
                    onChange={(selectedvalue) =>
                      HandleSelectedModule(selectedvalue)
                    }
                    options={FetchModulename.map((item) => ({
                      value: item.MODULENAME,
                      label: item.MODULENAME,
                    }))}
                  />
                </div>
              </div>
              <div>
                <h1 className="w-[180px]">
                  {" "}
                  Chapter Name <span style={{ color: "red " }}>*</span>
                </h1>
                <input
                  value={ChapterName}
                  type="text"
                  onChange={(e) => setChapterName(e.target.value)}
                />
              </div>
              <div>
                <h1> Select Area</h1>
                <div style={{ width: "300px", margin: "5px" }}>
                  <Select
                    isMulti
                    onChange={(selectedvalue) => HandleArea(selectedvalue)}
                    placeholder="Select an area..."
                    options={AreaDetails.map((item) => ({
                      value: item.AREANAME,
                      label: item.AREANAME,
                    }))}
                  />
                </div>
              </div>

              <div>
                <h1 className="w-[180px]">
                  Chapter Narration <span style={{ color: "red " }}>*</span>
                </h1>
                <textarea
                  value={ChapterNarration}
                  type="text"
                  onChange={(e) => setChapterNarration(e.target.value)}
                />
              </div>
            </main>
          </aside>
          <div>
            <section className="lg:flex gap-2 items-center ">
              <div className="searchhhhhh">
                <p>Search </p>
                <input
                  className="input_1"
                  required
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />

                <div className="search__icon">
                  <FaSearch />
                </div>
              </div>
              <div className="lg:flex gap-2 items-center ">
                <p>Chapter</p>
                <Select
                  options={chapterSelect}
                  onChange={setSelectedChapter}
                  placeholder="Select Chapter"
                  isClearable
                  className="lg:ml-2 lg:w-[350px]"
                />
              </div>
            </section>
            <aside
              className="
             table__video__app"
            >
              <table class="custom-table">
                <thead class="header-cell">
                  <td>MODULE&nbsp;ID</td>
                  <td>CHAPTER&nbsp;ID</td>
                  <td>CHAPTER&nbsp;NAME</td>
                  <td>CHAPTER&nbsp;NARRATION</td>

                  <td>EDIT</td>
                </thead>
                <tbody>
                  {filteredData.map((item, index) => (
                    <tr key={index} className="table-row">
                      <td className="alignleft">
                        {item.MODULEID ? item.MODULEID[0] : "N/A"}
                      </td>
                      <td className="alignleft">
                        {item.CHAPTERID ? item.CHAPTERID : "N/A"}
                      </td>
                      <td>{item.CHAPTERNAME ? item.CHAPTERNAME : "N/A"}</td>
                      <td style={{ maxWidth: "300px" }}>
                        {item.CHAPTERNARRATION ? item.CHAPTERNARRATION : "N/A"}
                      </td>

                      <td
                        onClick={(e) => {
                          handleClick(item);
                          scrollToSection();
                        }}
                      >
                        <FaRegEdit />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </div>
        </aside>{" "}
      </section>
    </div>
  );
};

export default ChapterList;
