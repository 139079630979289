import React from "react";
import { Link } from "react-router-dom";
const NavbarMain = () => {
  return (
    <div>
      <div>
        <nav className=" flex items-center justify-between w-[100%] bg-gradient-to-r h-[10vh] from-slate-800 to-violet-900 hover:from-blue-900 hover:to-violet-900 text-white">
          <header className="flex items-center justify-between px-10 md:px-[100px] py-2 w-[100%]">
            <Link to="/">
              {" "}
              <h2 className="text-[25px] font-bold">TecH-23</h2>
            </Link>
            <div class="hidden md:flex gap-8 font-semibold">
              <h5>
                <a href="#Solution"> Solution</a>
              </h5>
              <h5>
                {" "}
                <Link to="/">
                  {" "}
                  <a href="#About">About</a>
                </Link>
              </h5>
              <h5>
                {" "}
                <a href="#Support">Support</a>
              </h5>
            </div>
            <div>
              <header>
                {/* <ProfileIcon onClick={handleProfileClick} /> */}
                <Link to="/Login">
                  {" "}
                  <button className="bg-violet-500 text-white py-1 px-3 rounded hover:shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]">
                    Sign In
                  </button>
                </Link>
                {/* {logoutVisible && <LogoutOption />} */}
              </header>
            </div>
          </header>
        </nav>
      </div>
    </div>
  );
};

export default NavbarMain;
