import team1 from "./images/slide1.PNG";
import team2 from "./images/slide2.PNG";
import team3 from "./images/slide3.PNG";

import team5 from "./images/slide5.PNG";
import team6 from "./images/slide6.PNG";
import team7 from "./images/slide7.PNG";
import team8 from "./images/slide8.PNG";
import team9 from "./images/slide9.PNG";

export const countries = [
  {
    title: "Workshop System",
    image: team1 /* Add your image source here if available */,
    description: "Managing workshop systems efficiently.",
  },
  {
    title: "Inventory",
    image: team3 /* Add your image source here if available */,
    description: "Managing inventory and stock control.",
  },
  {
    title: "Production",
    image: team2 /* Add your image source here if available */,
    description: "Managing and optimizing production processes.",
  },
  {
    title: "Fuel Management",
    image: team5 /* Add your image source here if available */,
    description: "Efficiently managing fuel resources and consumption.",
  },
  {
    title: "Transport Operations",
    image: team6 /* Add your image source here if available */,
    description: "Managing transportation operations effectively.",
  },
  {
    title: "Tracking",
    image: team7 /* Add your image source here if available */,
    description: "Tracking and monitoring various aspects of operations.",
  },

  {
    title: "POS",
    image: team8 /* Add your image source here if available */,
    description: "Point of Sale (POS) system management.",
  },
  {
    title: "Property Management",
    image: team9 /* Add your image source here if available */,
    description: "Managing properties and real estate efficiently.",
  },
];
