import React from "react";
import { Link } from "react-router-dom";
import NavbarMain from "./NavbarMain";
const ForgotPwd = () => {
  return (
    <div>
      <NavbarMain />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <h1 className="text-[50px]"> Contact you Adminstrator.</h1>
        <h1>
          <Link to="/">
            {" "}
            <button className="bg-violet-700 hover:bg-violet-900 font-bold text-white py-2 px-10 rounded hover:shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]">
              Back
            </button>{" "}
          </Link>
        </h1>
        {/*  */}
      </div>
    </div>
  );
};

export default ForgotPwd;
