import React, { useEffect, useState } from "react";

import { BsFillMenuButtonWideFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
const SideBar = () => {
  const navigate = useNavigate();
  return (
    <main className=" fixed z-[0] hidden md:flex ">
      <div className="sidebar  bg-slate-50 h-[90vh] w-[240px] ">
        <div className="h-[50px] font-bold bg-[#002d62] flex items-center justify-between text-lg px-2  border-b-2 text-white">
          <h1>MODULES </h1>
          <p>
            <BsFillMenuButtonWideFill />
          </p>
        </div>

        <ul className=" font-[500] bg-slate-50    ">
          <li className={`cursor-pointer hover:bg-slate-200 p-2 border-b-2 `}>
            <li onClick={() => navigate("/Modules")}>MODULE MASTER</li>
          </li>
          <li className={`cursor-pointer hover:bg-slate-200 p-2 border-b-2 `}>
            <li onClick={() => navigate("/ChapterList")}>CHAPTER MASTER</li>
          </li>
          <li className={`cursor-pointer hover:bg-slate-200 p-2 border-b-2 `}>
            <li onClick={() => navigate("/Author")}>AUTHOR MASTER</li>
          </li>
          <li className={`cursor-pointer hover:bg-slate-200 p-2 border-b-2 `}>
            <li onClick={() => navigate("/Area")}>AREA MASTER</li>
          </li>
          <li className={`cursor-pointer hover:bg-slate-200 p-2 border-b-2 `}>
            <li onClick={() => navigate("/Video")}>VIDEO UPLOAD MASTER</li>
          </li>
          <li className={`cursor-pointer hover:bg-slate-200 p-2 border-b-2 `}>
            <li onClick={() => navigate("/ListofVideo")}>LIST OF VIDEO</li>
          </li>
        </ul>
      </div>
    </main>
  );
};

export default SideBar;
