import React from "react";
import "./UploadingSpinner.css";
const UploadingSpinner = () => {
  return (
    <div className="flex flex-col  w-[100%] h-[100vh]">
      <p class="UplodingStatus"></p>
      <h1 className="text-center font-bold text-xl uppercase">Uploading</h1>
    </div>
  );
};

export default UploadingSpinner;
