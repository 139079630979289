import React, { useState } from "react";
import axios from "axios";

function Test() {
  const CLIENT_ID = "512862d0-cddf-452a-9f44-a642a5a8db48";
  const CLIENT_SECRET = "3936ef83-9ccf-45ac-8c8b-33f490de9aed";
  const VIDEO_PATH = "/path/to/your/video/video.mp4";
  const UPLOAD_URL =
    "https://graph.microsoft.com/v1.0/me/drive/root:/FolderName/video.mp4:/content";

  const [accessToken, setAccessToken] = useState("");

  async function getAccessToken() {
    try {
      const tokenEndpoint =
        "https://login.microsoftonline.com/common/oauth2/v2.0/token";
      const requestBody = new URLSearchParams({
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        scope: "https://graph.microsoft.com/.default",
        grant_type: "client_credentials",
      });

      const response = await fetch(tokenEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: requestBody,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch access token");
      }

      const data = await response.json();
      setAccessToken(data.access_token); // Store access token in state
      console.log("Access token:", data.access_token);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <>
      <h1>Test page</h1>
      <input type="file" />
      <button onClick={getAccessToken}>Submit</button>
    </>
  );
}

export default Test;
